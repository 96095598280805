import PropTypes from 'prop-types';
import React from 'react';

import instagram from '../images/instagram_logo.svg';
import facebook from '../images/facebook_logo.svg';
import linkedin from '../images/linkedin_logo.svg';


// const PreNavbar = ({ site }) => {
    const PreNavbar = () => {

    return (
        <>
            <div className="w-full navbarcolor h-10 flex items-center justify-between px-20 text-sm shadow">

                <div className="text-gray-100 flex">CONSULTORA IT LÍDER EN ARGENTINA</div>
                

                <div className="hidden md:flex">
                <a href="https://www.linkedin.com/company/feedback-it" target="_blank" rel="noopener noreferrer"> <img className="mx-2" style={{ maxHeight: '20px',}} src={linkedin} /> </a>
                <a href="https://www.instagram.com/feedback.ti/" target="_blank" rel="noopener noreferrer"> <img className="mx-2" style={{ maxHeight: '20px',}} src={instagram} /> </a>
                <a href="https://www.facebook.com/Feedback.LATAM" target="_blank" rel="noopener noreferrer"> <img className="mx-2" style={{ maxHeight: '20px',}} src={facebook} /> </a>
                    {/* <div className="mx-3 flex items-center">
                        <a href={`tel:${site.siteMetadata.contact.phone}`} className="no-underline hover:underline text-gray-100 hover:text-blue-100">
                            Tel: {site.siteMetadata.contact.phone}
                        </a>
                    </div> */}
                    {/* <div className="mx-1 flex items-center">
                        <a href='https://www.facebook.com/Feedback.LATAM/'>
                            <img className="w-1/12" src={facebookIcon} />
                        </a>
                    </div>
                    <div className="mx-1 flex items-center">
                        <a href='https://ar.linkedin.com/company/feedback-it'>
                            <img className="w-1/12" src={linkedinIcon} />
                        </a>
                    </div> */}
                    {/* <div className="mx-3 flex items-center">
                        <a href={`mailto:${site.siteMetadata.contact.mail}`} className="no-underline hover:underline text-gray-100 hover:text-blue-100">
                            {site.siteMetadata.contact.mail}
                        </a>
                    </div> */}
                </div>

            </div>
        </>
    );
}

PreNavbar.propTypes = {
    site: PropTypes.object.isRequired,
};

export default PreNavbar;