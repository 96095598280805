import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";

import { graphql, useStaticQuery } from "gatsby";

import NavBar from "./NavBar";
import Footer from "./Footer";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

function Layout({ children }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          contact {
            mail
            phone
          }
        }
      }
    }
  `);

  const urlPathname =
    typeof window !== "undefined" ? window.location.pathname : null;
  let gradient =
    urlPathname === "/servicios-digitales" ? "gradient2" : "gradient";
  let color =
    urlPathname === "/servicios-digitales" ? "text-pink-700" : "text-blue-700";

  console.log(
    "%c%s",
    "color: #00246F; font-size: 15px;",
    "By: Nahuel Krowicki - https://nahuel.dev"
  );

  return (
    <>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Feedback IT"
      />
      <div className={`leading-normal tracking-normal text-white ${gradient}`}>
        <NavBar clases={gradient} site={site} />

        {children}

        <Footer colorText={color} site={site} />
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
