import PropTypes from 'prop-types';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { Link } from 'gatsby';

import instagram_azul from '../images/instagram_logo_azul.svg';
import facebook_azul from '../images/facebook_logo_azul.svg';
import linkedin_azul from '../images/linkedin_logo_azul.svg';

import instagram_purpura from '../images/instagram_logo_purpura.svg';
import facebook_purpura from '../images/facebook_logo_purpura.svg';
import linkedin_purpura from '../images/linkedin_logo_purpura.svg';


const Footer = ({colorText}) => {

    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: {eq: "fdbk_logo_sintexto.png"}){
                sharp: childImageSharp{
                    fluid{
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
        }
        }
        `);


    let instagram = colorText === 'text-pink-700' ? instagram_purpura : instagram_azul ;
    let facebook = colorText === 'text-pink-700' ?  facebook_purpura : facebook_azul;
    let linkedin = colorText === 'text-pink-700' ? linkedin_purpura : linkedin_azul;

    return (
        <>
            <footer className="bg-white border-t">
                <div className="container mx-auto px-8">
                    <div className="w-full flex flex-col md:flex-row justify-between content-center">

                        <div className="mt-3 flex flex-row justify-center items-center">
                            
                        
                            <a href="https://www.linkedin.com/company/feedback-it" target="_blank" rel="noopener noreferrer"> <img className="mx-2" style={{ maxHeight: '40px', height:'40px'}} src={linkedin} /> </a>
                            <a href="https://www.instagram.com/feedback.ti/" target="_blank" rel="noopener noreferrer"> <img className="mx-2" style={{ maxHeight: '40px',height:'40px'}} src={instagram} /> </a>
                            <a href="https://www.facebook.com/Feedback.LATAM" target="_blank" rel="noopener noreferrer"> <img className="mx-2" style={{ maxHeight: '36px',height:'36px'}} src={facebook} /> </a>
                            
                        </div>
                        
                        <div className="mt-3 flex flex-col justify-center items-center">
                            <p className={`${colorText} text-gray-800 `}>Your growth. Our mission</p>
                            
                        </div>

                        <div className="mt-2 mb-2">
                            <Link to={'/'} className="flex justify-center items-center" >
                                <Image
                                    style={{ maxHeight: '85px', filter: 'brightness(100%)', width: '12.5rem',}}
                                    imgStyle={{ objectFit: 'contain' }}
                                    fluid={image.sharp.fluid} />

                                {/* <p className="text-gray-800 no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-center">{site.siteMetadata.title}</p> */}
                            </Link>
                            {/* <div className="flex align-center justify-center mt-2 inline-block mr-2 md:block md:mr-0 text-center">
                                    <p className="no-underline hover:underline text-gray-800 hover:text-blue-500">By <a className="font-bold" href="https://nubedi.com">Nubedi</a></p>
                                </div> */}
                        </div>

                    </div>
                </div>
            </footer>

        </>
    );
}

Footer.propTypes = {
    colorText: PropTypes.string, 
};

export default Footer;