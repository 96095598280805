import PropTypes from 'prop-types';

import React, { useState, useEffect } from 'react';

import { Link } from "gatsby";
import { useWindowScroll } from "react-recipes";

import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import PreNavbar from './PreNavbar';


const NavBar = ({ site, clases }) => {

  const [isExpanded, toggleExpansion] = useState(false);
  const [scroll, setScroll] = useState({});
  const { y } = useWindowScroll();



  const { image } = useStaticQuery(graphql`
  query {
      image: file(relativePath: {eq: "fdbk_blanco.png"}){
          sharp: childImageSharp{
              fluid{
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
  }
  }
  `);



  useEffect(() => {

    if (y > 10) {
      setScroll({
        stylesNav: `${clases} shadow fixed`,
        textColor: 'text-gray-800',
        buttonCTA: 'bg-white text-gray-800',
        // buttonCTA: 'gradient text-white',
        // stylesLogo: { maxHeight: '65px', width: '3.5rem', filter: 'brightness(95%)' }
        stylesLogo: { maxHeight: '100px', width: '15.5rem',  }

      })
    } else {
      setScroll({
        stylesNav: '',
        textColor: '',
        buttonCTA: 'bg-white text-gray-800',
        stylesLogo: { maxHeight: '100px', width: '15.5rem',  }
      })
    }



  }, [y])



  return (
    <>

  <PreNavbar site={site}/>


    <nav className={`${scroll.stylesNav} w-full z-30 top-0 text-white`}>

      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">


        <Link to="/">
          <div className="pl-4 flex items-center">
            <div className={`flex items-center toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl ${scroll.textColor}`} href="#">

              <Image
                style={{ ...scroll.stylesLogo }}
                imgStyle={{ objectFit: 'contain' }}
                fluid={image.sharp.fluid} />

              {/* {site.siteMetadata.title} */}

            </div>
          </div>
        </Link>

        <div className="block lg:hidden pr-4">
          <button className="flex items-center p-1 text-white-800 hover:text-gray-700" onClick={() => toggleExpansion(!isExpanded)}>
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>

        <div className={`w-full lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 bg-gray-800 lg:bg-transparent text-white p-4 lg:p-0 z-20  ${isExpanded ? `block` : `hidden`}`} id="nav-content">

          <ul className="list-reset lg:flex justify-end flex-1 items-center">

            {[
              {
                route: `/`,
                title: `Nosotros`,
              },
              {
                route: `/soluciones-it`,
                title: `Soluciones IT`,
              },
              {
                route: `/servicios-digitales`,
                title: `Servicios Digitales`,
              },
            ].map((link) => (
              <li
                className="mr-3"
                key={link.title}
              >
                <Link
                  className="inline-block text-white no-underline hover:text-yellow-500 hover:text-underline py-2 px-4"
                  to={link.route}
                  activeClassName="font-bold text-yellow-500 no-underline"
                >
                  {link.title}
                </Link>
              </li>
            ))}
                <li
                 className="mr-3"
                >
                  <a
                    className="inline-block text-white no-underline hover:text-yellow-500 hover:text-underline py-2 px-4"
                    href='https://blog.feedback-it.com'
                    activeClassName="font-bold text-yellow-500 no-underline"
                    >
                    Noticias
                  </a>
                </li>
            <Link
              to={'/contacto'}>
              <button id="navAction" className={`${scroll.buttonCTA} mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 md:mt-0 py-4 px-8 shadow opacity-75`}>Contacto</button>
            </Link>
          </ul>
        </div>
      </div >
    </nav >
    </>
  );
}


NavBar.propTypes = {
  site: PropTypes.object.isRequired,
  clases: PropTypes.string
};

export default NavBar;